import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { OrderCreateModel, OrderListModel } from '../_models/order/orderModel';

import { WindowReferenceService } from './window-reference.service';


@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  private months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private window;

  constructor(private _windowRef: WindowReferenceService, private cookieService: CookieService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service

  }

  private pingHome(obj) {  
    if (obj) this.window.dataLayer.push(obj);
  }

  //list of all our dataLayer methods

  logPageView(url) {

    var d = new Date();
    let hit = {
      event: 'page-view',
      event_url: "madstarmedia.co.uk"+url,
      creator: "Madstar Media",
      event_time: d.getUTCHours(),
      event_day: this.days[d.getDay()],
      event_month: this.months[d.getMonth()],
      utm_campaign: this.cookieService.get("utm_campaign"),
      last_utm_campaign: this.cookieService.get("last_utm_campaign"),
      utm_content: this.cookieService.get("utm_content"),
      last_utm_content: this.cookieService.get("last_utm_content"),
      utm_medium: this.cookieService.get("utm_medium"),
      last_utm_medium: this.cookieService.get("last_utm_medium"),
      utm_source: this.cookieService.get("utm_source"),
      last_utm_source: this.cookieService.get("last_utm_source"),
      utm_terms: this.cookieService.get("utm_terms"),
      last_utm_terms: this.cookieService.get("last_utm_terms"),
    };

    this.pingHome(hit);
  }
  requestOpenWindow(number, price, url) {

    var d = new Date();
    const hit = {
      event: 'open-cart',
      event_url: "madstarmedia.co.uk" + url,
      creator: "Madstar Media",
      event_time: d.getUTCHours(),
      event_day: this.days[d.getDay()],
      event_month: this.months[d.getMonth()],     
      order_value: price ? price : undefined,
      order_content_ids: number ? number : undefined,
      order_content_name: "Catastrophic Bags - x" + number,
      utm_campaign: this.cookieService.get("utm_campaign"),
      last_utm_campaign: this.cookieService.get("last_utm_campaign"),
      utm_content: this.cookieService.get("utm_content"),
      last_utm_content: this.cookieService.get("last_utm_content"),
      utm_medium: this.cookieService.get("utm_medium"),
      last_utm_medium: this.cookieService.get("last_utm_medium"),
      utm_source: this.cookieService.get("utm_source"),
      last_utm_source: this.cookieService.get("last_utm_source"),
      utm_terms: this.cookieService.get("utm_terms"),
      last_utm_terms: this.cookieService.get("last_utm_terms"),
    }    
    this.pingHome(hit);

  }

  requestAddToCart(request: OrderCreateModel,price, url) {
    var d = new Date();
    const hit = {
      event: 'add-to-cart',
      event_url: "madstarmedia.co.uk" + url,
      creator: "Madstar Media",
      event_time: d.getUTCHours(),
      event_day: this.days[d.getDay()],
      event_month: this.months[d.getMonth()],
      order_email: request.email ? request.email : undefined,
      order_name: request.name ? request.name : undefined,
      order_city: request.city ? request.city : undefined,
      order_postal_code: request.postalCode ? request.postalCode : undefined,
      order_value: price ? price : undefined,
      order_content_ids: request.quantity ? request.quantity : undefined,
      order_content_name: "Catastrophic Bags - x" + request.quantity,
      utm_campaign: this.cookieService.get("utm_campaign"),
      last_utm_campaign: this.cookieService.get("last_utm_campaign"),
      utm_content: this.cookieService.get("utm_content"),
      last_utm_content: this.cookieService.get("last_utm_content"),
      utm_medium: this.cookieService.get("utm_medium"),
      last_utm_medium: this.cookieService.get("last_utm_medium"),
      utm_source: this.cookieService.get("utm_source"),
      last_utm_source: this.cookieService.get("last_utm_source"),
      utm_terms: this.cookieService.get("utm_terms"),
      last_utm_terms: this.cookieService.get("last_utm_terms"),
    }
    this.pingHome(hit);
  }


  requestChangeProducts(quantity, url) {
    var d = new Date();
    const hit = {
      event: 'requestChangeProducts',
      event_url: "madstarmedia.co.uk" + url,
      creator: "Madstar Media",
      event_time: d.getUTCHours(),
      event_day: this.days[d.getDay()],
      event_month: this.months[d.getMonth()],  
      order_content_name: "Catastrophic Bags - x" + quantity,
      utm_campaign: this.cookieService.get("utm_campaign"),
      last_utm_campaign: this.cookieService.get("last_utm_campaign"),
      utm_content: this.cookieService.get("utm_content"),
      last_utm_content: this.cookieService.get("last_utm_content"),
      utm_medium: this.cookieService.get("utm_medium"),
      last_utm_medium: this.cookieService.get("last_utm_medium"),
      utm_source: this.cookieService.get("utm_source"),
      last_utm_source: this.cookieService.get("last_utm_source"),
      utm_terms: this.cookieService.get("utm_terms"),
      last_utm_terms: this.cookieService.get("last_utm_terms"),
    }
    this.pingHome(hit);
  }

  requestPurchasEvent(request: OrderListModel, url) {
    var d = new Date();
    const hit = {
      event: 'purchase',
      event_url: "madstarmedia.co.uk" + url,
      creator: "Madstar Media",
      event_time: d.getUTCHours(),
      event_day: this.days[d.getDay()],
      event_month: this.months[d.getMonth()],       
      order_email: request.email ? request.email : undefined,
      order_name: request.name ? request.name : undefined,
      order_city: request.city ? request.city : undefined,
      order_postal_code: request.postalCode ? request.postalCode : undefined,
      order_value: request.price ? request.price : undefined,
      order_content_ids: request.quantity ? request.quantity : undefined,
      order_content_name: "Catastrophic Bags - x" + request.quantity, 
      utm_campaign: this.cookieService.get("utm_campaign"),
      last_utm_campaign: this.cookieService.get("last_utm_campaign"),
      utm_content: this.cookieService.get("utm_content"),
      last_utm_content: this.cookieService.get("last_utm_content"),
      utm_medium: this.cookieService.get("utm_medium"),
      last_utm_medium: this.cookieService.get("last_utm_medium"),
      utm_source: this.cookieService.get("utm_source"),
      last_utm_source: this.cookieService.get("last_utm_source"),
      utm_terms: this.cookieService.get("utm_terms"),
      last_utm_terms: this.cookieService.get("last_utm_terms"),
    }
    this.pingHome(hit);
  }


}
