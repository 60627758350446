import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserUpdate } from '../_models/user';
import { HttpClient } from '@angular/common/http';
import { UserParams } from '../_models/table/userParams';
import { getPaginatedResult, getPaginationHeaders } from '../_helpers/paginationHelper';


@Injectable({
  providedIn: 'root'
})

export class AccountService {

  baseUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();

  constructor(private http: HttpClient, private router: Router,
  ) { }

  getUserList(userParams: UserParams) {
    let params = getPaginationHeaders(userParams.pageNumber.toString(), userParams.pageSize.toString());

    params = params.append('orderBy', userParams.orderBy);
    params = params.append('searchText', userParams.searchText);

    return getPaginatedResult<User[]>(this.baseUrl + "/Account/GetUsersList", params, this.http);
  }

  getUserProfile() {
    return this.http.get<any>(this.baseUrl + '/account/GetUserDetails');
  }

  ChangeUserPassword(oldPassword: string, password: string) {

    let form = new FormData();
    form.append('newPassword', password);
    form.append('currentPassword', oldPassword);

    return this.http.put<any>(this.baseUrl + '/account/UpdateUserPassword', form);
  }


  resetPassword(email: string) {
    return this.http.post<any>(this.baseUrl + '/account/ResetPassword/' + email, {});
  }

  login(model: any) {
    return this.http.post<User | false>(this.baseUrl + '/account/login', model).pipe(
      map((response: User) => {
        const user = response;

        this.setCurrentUser(user);


        return true;
      }
      ))
  }


  updateUserCode(userUpdate: UserUpdate) {
   
    return this.http.put<UserUpdate>(this.baseUrl + '/account/UpdateUserCode', userUpdate);

  }


  register(model: any) {

    return this.http.post<User>(this.baseUrl + '/account/register', model).pipe(
      map((user: User) => {
        if (user) {
          this.setCurrentUser(user);
        }
        return user;
      })
    )
  }


  setCurrentUser(user: User) {
    user.roles = [];
    const roles = this.getDecodedToken(user.token).role;
    Array.isArray(roles) ? user.roles = roles : user.roles.push(roles);

    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
  }

  logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);
    this.router.navigate(['/login']);

  }

  getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }



}
