import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { PreloadAllModules, provideRouter, RouterModule, Routes } from '@angular/router';
import { CommonModule, PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { AdminGuard } from './_guards/admin.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';





const routes: Routes = [
   //{ path: 'salesPage', component: SalesPage1Component },
   {
    path: '',
    loadChildren: () => import('./sales-page/sales-page.module').then((x) => x.SalesPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./policy/policy.module').then((x) => x.PolicyModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then((x) => x.TermsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authorization/login/login.module').then((x) => x.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./authorization/register/register.module').then((x) => x.RegisterModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./stripe-success/checkout.module').then((x) => x.CheckoutModule)
  },
  {
    path: 'orders', canActivate: [AdminGuard],
    loadChildren: () => import('./panel/orders-list/orders.module').then((x) => x.OrdersModule)
  },
  {
    path: 'products', canActivate: [AdminGuard],
    loadChildren: () => import('./panel/product/product.module').then((x) => x.ProductModule)
  },
  {
    path: '**',
    loadChildren: () => import('./sales-page/sales-page.module').then((x) => x.SalesPageModule)
  },
]


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,

   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideRouter(routes),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, 
   
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
