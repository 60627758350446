import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { User } from './_models/user';
import { AccountService } from './_services/account.service';
import { DataLayerService } from './_services/data-layer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Catastrophicbags';

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  

  constructor(private accountService: AccountService,
    private _router: Router, private route: ActivatedRoute, private _dataLayerService: DataLayerService,
    private cookieService: CookieService,
   /* private ccService: NgcCookieConsentService*/) {
  }
  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {

      if (params['utm_campaign'] != undefined) {
        this.cookieService.set("last_utm_campaign", params['utm_campaign']);
      }
      if (params['utm_source'] != undefined) {
        this.cookieService.set("last_utm_source", params['utm_source'])
      }
      if (params['utm_content'] != undefined) {
        this.cookieService.set("last_utm_source", params['utm_source'])
      }
      if (params['utm_terms'] != undefined) {
        this.cookieService.set("last_utm_terms", params['utm_terms'])
      }
      if (params['utm_medium'] != undefined) {
        this.cookieService.set("last_utm_medium", params['utm_medium'])
      }
      //First visit     

      if (!this.cookieService.get("utm_campaign") && params['utm_campaign'] != undefined) {
        this.cookieService.set("utm_campaign", params['utm_campaign'])
      }
      if (!this.cookieService.get("utm_source") && params['utm_source'] != undefined) {
        this.cookieService.set("utm_source", params['utm_source'])
      }
      if (!this.cookieService.get("utm_content") && params['utm_content'] != undefined) {
        this.cookieService.set("utm_source", params['utm_source'])
      }
      if (!this.cookieService.get("utm_terms") && params['utm_terms'] != undefined) {
        this.cookieService.set("utm_terms", params['utm_terms'])
      }
      if (!this.cookieService.get("utm_medium") && params['utm_medium'] != undefined) {
        this.cookieService.set("utm_medium", params['utm_medium'])
      }
    });






      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
         // this._dataLayerService.logPageView(event.url)
        }
      });
 
      this.setCurrentUser();
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  setCurrentUser() {
    const user: User | null = JSON.parse(localStorage['user'] == null ? null : localStorage['user'])
    if (user) {
      this.accountService.setCurrentUser(user);
    }
  }
}
